import styled from "@emotion/styled";
import React from "react";
import SectionTitle from "../components/common/SectionTitle";
import Layout from "../components/layout";
import BorderButton from "../components/common/BorderButton";

export default function Thanks() {

    return (
        <Layout>
            <Container>
                <div>
                    <SectionTitle title="Thanks"/>
                    <p className="contact_greeting">
                        お問い合わせいただき、ありがとうございました。<br/>
                        追ってスタッフより返信させていただきます。</p>
                    <div className="top-button">
                        <BorderButton title="トップへ戻る" to="/"/>
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

const Container = styled.div`
  background-color: black;
  color: #fff;
  max-width: 528px;
  margin: 0 auto;
  padding: 100px 24px;
  width: 100%;
  height: 80vh;
  min-height: 600px;
  vertical-align: middle;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1ch;
  
  .top-button{
    text-align: center;
    width: 100%;
    margin: 30px auto ;
  }

  .contact_greeting {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 26px;

    color: #fff;
  }

  input {
  }
`;
